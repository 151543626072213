import {
  PersonAdd as AddUserIcon,
  AccountBalance as ExchangeIcon,
  Login as LoginIcon,
  TrendingUp as StrategyIcon,
  AttachMoney as TradingIcon,
  GridView as UserGridIcon,
  CallSplit as WebhookIcon,
} from "@mui/icons-material";
import DashboardIcon from "./Dashboard";
import SessionsIcon from "./Sessions";
import UserManagementIcon from "./UserManagement";
import UserProfileIcon from "./UserProfile";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LoginIcon,
  AddUserIcon,
  SessionsIcon,
  UserGridIcon,
  ExchangeIcon,
  StrategyIcon,
  TradingIcon,
  WebhookIcon,
  DashboardIcon,
  UserProfileIcon,
  UserManagementIcon,
};
