import { Snackbar } from "@mui/material";
import { AppContext, SnackBarType } from "contexts/AppContext";
import React, { useContext } from "react";

export default function SnackBar() {
  const { snackBar, closeSnackBar } = useContext(AppContext);

  const handleClose = (
    event: React.SyntheticEvent | MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    closeSnackBar();
  };

  const getSnackBarType = () => {
    switch (snackBar.type) {
      case SnackBarType.SUCCESS:
        return "success";
      case SnackBarType.WARNING:
        return "warning";
      case SnackBarType.ERROR:
        return "error";
      default:
        return undefined;
    }
  };

  return (
    <Snackbar
      open={snackBar.open}
      onClose={handleClose}
      autoHideDuration={snackBar.duration}
      message={snackBar.message}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      color={getSnackBarType()}
    />
  );
}
