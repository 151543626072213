import React, { createContext, useState } from "react";

export enum SnackBarType {
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
}

interface SnackBarState {
  open: boolean;
  duration: number;
  message: string;
  type: string;
}

interface AppContextValue {
  snackBar: SnackBarState;
  openSnackBar: (
    message: string,
    type?: SnackBarType,
    duration?: number
  ) => void;
  closeSnackBar: () => void;
}

export const AppContext = createContext<AppContextValue>(null as any);

export const AppProvider: React.FC = ({ children }) => {
  const [snackBar, setSnackBar] = useState<SnackBarState>({
    open: false,
    duration: 6000, // default duration is 6 seconds
    message: "",
    type: "SUCCESS",
  });

  const openSnackBar = (
    message: string,
    type = SnackBarType.SUCCESS,
    duration = 6000
  ) => {
    setSnackBar({
      open: true,
      duration,
      message,
      type,
    });
  };

  const closeSnackBar = () => {
    setSnackBar({
      ...snackBar,
      open: false,
    });
  };

  const contextValue: AppContextValue = {
    snackBar,
    openSnackBar,
    closeSnackBar,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
