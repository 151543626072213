import Icons from "icons/sidebar";

const index = [
  {
    title: "Dashboard",
    Icon: Icons.DashboardIcon,
    path: "/dashboard",
  },
  {
    title: "Trading",
    Icon: Icons.TradingIcon,
    path: "/trading",
  },
  {
    title: "User Profile",
    Icon: Icons.UserProfileIcon,
    path: "/dashboard/user-profile",
  },
  {
    title: "Exchanges",
    Icon: Icons.ExchangeIcon,
    path: "/dashboard/exchanges",
  },
  {
    title: "News Strategies",
    Icon: Icons.StrategyIcon,
    path: "/dashboard/news-strategies",
  },
  {
    title: "Webhooks",
    Icon: Icons.WebhookIcon,
    path: "/dashboard/webhooks",
  },
];

export default index;
