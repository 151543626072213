import { CssBaseline, ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import SnackBar from "components/SnackBar";
import { AppProvider } from "contexts/AppContext";
import { FC } from "react";
import { Toaster } from "react-hot-toast";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import { getTheme } from "./theme";

const App: FC = () => {
  const allPages = useRoutes(routes);

  // App theme
  const appTheme = getTheme();

  // toaster options
  const toasterOptions = {
    style: {
      fontWeight: 500,
      fontFamily: `apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif`,
    },
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <AppProvider>
          <CssBaseline />
          <Toaster toastOptions={toasterOptions} />
          <SnackBar />
          {allPages}
        </AppProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
